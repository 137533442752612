<template>
  <el-table
    :data="beneficiaries"
    stripe
  >
    <el-table-column label="Acciones" width="100">
      <template slot-scope="scope">
        <el-button
          @click="goToBeneficiary(scope.$index, scope.row)"
          size="mini"
        >
            Seleccionar
        </el-button>
      </template>
    </el-table-column>
    <el-table-column prop="nombre" label="Nombre" width="100" />
    <el-table-column prop="fecha_inicio_obra" label="Inicio de Obra" width="150" />
    <el-table-column prop="fecha_termino" label="Fin de Obra" width="150" />
    <el-table-column prop="nombre_estado" label="Estado" width="100" />
    <el-table-column prop="nombre_localidad" label="Localidad" width="100" />
  </el-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BeneficiariesTable',
  computed: {
    ...mapGetters('beneficiaries', [
      'beneficiaries'
    ])
  },
  mounted () {
    this.getBeneficiaries()
  },
  methods: {
    ...mapActions('beneficiaries', [
      'getBeneficiaries'
    ]),
    goToBeneficiary (index, row) {
      this.$router.push({ name: 'Beneficiary' })
    }
  }
}
</script>

<style scoped lang="scss">
.beneficiaries-table {
  display: grid;
}
</style>
