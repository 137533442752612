<template>
  <div class="home">
    <h1>Lista de Beneficiarios</h1>
    <BeneficiariesTable />
  </div>
</template>

<script>
import BeneficiariesTable from '@/components/BeneficiariesTable'

export default {
  name: 'Home',
  components: {
    BeneficiariesTable
  }
}
</script>

<style scoped lang="scss">
.home {
  margin: 1rem 2rem;
}
</style>
